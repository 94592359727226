<template>
  <div class="voucher_tmp_view">
    <div class="content-select">
      <div class="search_box">
        <el-form ref="filterForm" :model="queryParams" inline label-width="80px">
          <el-row class="row-first">
            <el-form-item label="模板名称" prop="tmplName">
              <el-input v-model="queryParams.tmplName" placeholder="请输入模板名称" clearable @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="业务类型" prop="bizType">
              <el-select v-model="queryParams.bizType" clearable placeholder="请选择业务类型">
                <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="default" @click="resetQuery">重置</el-button>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <el-row v-show="records.length != 0" style="margin: 0 0 20px 14px">
        <el-button size="small" type="primary" @click="handleAdd">新增模版</el-button>
      </el-row>
      <el-row v-show="records.length == 0" class="add_template">
        <img src="../../../assets/img/cooperate/scanning-noData.png" alt="" />
        <div style="margin: 20px 0">暂无凭证模版数据！</div>
        <el-button size="small" type="primary" @click="handleAdd">新增模版</el-button>
      </el-row>
      <el-row :gutter="25" v-loading="loading">
        <el-col :span="6" v-for="(item, index) in records" :key="item.id" style="margin-bottom: 20px">
          <div class="tmp_item">
            <div class="tit">
              <span>
                <el-tag effect="plain" :type="item.bizType == 'II' ? 'success' : 'warning'" size="mini">{{ item.bizType == 'SI' ? '销项' : '进项' }}</el-tag
                >&nbsp;&nbsp;{{ item.tmplName }}</span
              >
              <div class="btn_box">
                <el-button type="text" size="mini" @click="handleItem(item, 'edit', index)">编辑</el-button>
                <el-button type="text" size="mini" @click="handleItem(item, 'del', index)">删除</el-button>
                <span>&nbsp;&nbsp;</span>
              </div>
            </div>
            <div class="con_box">
              <p class="con" v-for="v in item['items']" :key="v.id">
                {{ v.accountDirection === 'J' ? '借：' : '贷：' }}{{ v.accountName }}
                <!-- （{{ v.accountValueFrom == '1' ? '不含税' : v.accountValueFrom == '2' ? '税额' : '价税合计' }}） -->
              </p>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="content-page" v-show="records.length > 0">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 30, 50, 100]" :page-size="queryParams.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>

    <el-dialog class="tmp_dialog" width="690px" :visible.sync="visiable" :title="dialogType == 'add' ? '新增凭证模板' : '编辑'">
      <div class="con">
        <div class="ipt_box">
          <label> <span style="color: red">*</span>业务类型</label>
          <el-select class="cus_tmp_select" filterable placeholder="请选择" size="mini" v-model="bizType">
            <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="ipt_box">
          <label> <span style="color: red">*</span>模板名称</label>
          <el-input size="mini" v-model="tmplName" placeholder="请填写模板名称" style="width: 258px" clearable></el-input>
        </div>
        <el-table :data="voucherTmpList" border class="cus_tmp_table">
          <el-table-column width="40" prop="add" align="center" :render-header="handleRenderHeader">
            <template slot-scope="scope">
              <i class="el-icon-remove-outline" style="cursor: pointer; color: red; font-size: 18px" @click="handleTableDelItem(scope.row, scope.$index)"></i>
            </template>
          </el-table-column>
          <el-table-column prop="cur_qjkm" label="会计科目" align="center">
            <template slot-scope="scope">
              <el-select class="cus_tmp_select" filterable placeholder="请选择" size="mini" v-model="scope.row.cur_qjkm" @change="handleChooseKJKM($event, scope.$index)">
                <el-option v-for="item in scope.row.kjku" :key="item.id" :label="item.accountFullName" :value="JSON.stringify(item)"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="cur_fx" label="方向" width="120" align="center">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.$index, 'fx')">
                <span class="el-dropdown-link">{{ scope.row.cur_fx }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                <el-dropdown-menu slot="dropdown" trigger="click">
                  <el-dropdown-item v-for="item in scope.row.fx" :key="item.value" :command="JSON.stringify(item)">{{ item.label }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template></el-table-column
          >
          <el-table-column prop="cur_qz" label="取值" width="160" align="center">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.$index, 'qz')">
                <span class="el-dropdown-link">{{ scope.row.cur_qz }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                <el-dropdown-menu slot="dropdown" trigger="click">
                  <el-dropdown-item v-for="item in scope.row.qz" :key="item.value" :command="JSON.stringify(item)">{{ item.label }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="button-footer">
        <el-button type="default" @click="visiable = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleDialog">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { voucherTmpl, voucherAdd, getVoucherAccounts, voucherTmplDel, voucherTmplItemUpdate } from '@/service/voucher';
export default {
  components: {},
  data() {
    return {
      popVisible: false,
      visiable: false,
      name: '',
      types: [
        {
          value: 'SI',
          label: '销项'
        },
        {
          value: 'II',
          label: '进项'
        }
      ],
      queryParams: {
        tmplName: undefined,
        bizType: undefined,
        page: 1,
        size: 10
      },
      loading: false,
      tmplName: '',
      bizType: '',
      records: [],
      total: 0,
      dialogType: 'add', //add edit
      voucherTmpList: [],
      voucherItem: {
        accountid: '',
        accountdirection: '',
        accountvaluefrom: '',
        accountlName: '',
        cur_fx: '请选择',
        cur_qz: '请选择',
        kjku: [],
        id: '',
        fx: [
          { label: '借', value: 'J' },
          { label: '贷', value: 'D' }
        ],
        qz: [
          { label: '不含税', value: 1 },
          { label: '税额', value: 2 },
          { label: '价税合计', value: 3 }
        ]
      },
      curTmplId: '',
      tmpVoucherItem: {},
      tmpVoucherTmpList: []
    };
  },
  created() {
    this.getList();
    this._getVoucherAccounts();
  },
  methods: {
    handleQuery() {
      this.getList();
    },
    /** 重置 */
    resetQuery() {
      this.queryParams = {
        tmplName: undefined,
        bizType: undefined,
        page: 1,
        size: 10
      };
      this.getList();
    },
    handleItem({ bizType, tmplName, items, id }, type, index) {
      this.dialogType = type;
      this.curTmplId = this.records[index].id;
      switch (type) {
        case 'edit':
          this.visiable = true;
          this.bizType = bizType;
          this.tmplName = tmplName;
          this.voucherTmpList = [];
          // !item.accountid || !item.accountdirection || !item.accountvaluefrom;
          items.forEach((item) => {
            this.voucherItem.accountdirection = item.accountDirection;
            this.voucherItem.accountvaluefrom = item.accountValueFrom;
            this.voucherItem.cur_qjkm = item.accountName;
            this.voucherItem.cur_fx = item.accountDirection == 'J' ? '借' : '贷';
            this.voucherItem.cur_qz = item.accountValueFrom == '1' ? '不含税' : item.accountValueFrom == 2 ? '税额' : '价税合计';
            this.voucherItem.id = item.id;
            this.voucherItem.accountid = item.accountId;
            this.voucherItem.accountName = item.accountName;
            this.voucherTmpList = JSON.parse(JSON.stringify([...this.voucherTmpList, this.voucherItem]));
          });

          break;
        case 'del':
          this.$confirm('确定要删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              voucherTmplDel(id).then((res) => {
                res.success && this.toast('操作成功', 'success');
                this.getList();
              });
            })
            .catch(() => {
              // this.toast('取消清空', 'warning');
            });
          break;
        default:
          break;
      }
    },
    // 选择会计科目
    handleChooseKJKM(item, index) {
      const tmpItem = JSON.parse(item);
      this.voucherTmpList[index].accountName = tmpItem.accountFullName;
      this.voucherTmpList[index].accountid = tmpItem.id;
    },
    // 选择方向或者取值
    handleCommand(c, index, type) {
      const tmpItem = JSON.parse(c);
      switch (type) {
        case 'fx':
          this.voucherTmpList[index].cur_fx = tmpItem.label;
          this.voucherTmpList[index].accountdirection = tmpItem.value;
          break;
        case 'qz':
          this.voucherTmpList[index].cur_qz = tmpItem.label;
          this.voucherTmpList[index].accountvaluefrom = tmpItem.value;
          break;
        default:
          break;
      }
    },
    async _getVoucherAccounts() {
      const { data } = await getVoucherAccounts();
      this.voucherItem.kjku = data;
      this.tmpVoucherItem = JSON.parse(JSON.stringify(this.voucherItem));
      this.voucherTmpList = JSON.parse(JSON.stringify([this.voucherItem, this.voucherItem])); // //至少二行
      this.tmpVoucherTmpList = JSON.parse(JSON.stringify(this.voucherTmpList));
    },
    handleRenderHeader(h, { column, $index }) {
      console.log(h, column, $index);
      return h('div', [
        // h('span', column.label),
        h('i', {
          class: 'el-icon-circle-plus',
          style: 'cursor: pointer;color:#3D94FF;font-size:20px',
          on: {
            click: this.handleTableAddItem
          }
        })
      ]);
    },
    // 新增一行
    handleTableAddItem() {
      if (this.voucherTmpList.length === 20) return this.toast('凭证模版最多只能添加20行！', 'warning');
      this.voucherItem = {
        ...this.tmpVoucherItem,
        cur_qjkm: '',
        cur_fx: '请选择',
        cur_qz: '请选择'
      };
      this.voucherTmpList = JSON.parse(JSON.stringify([...this.voucherTmpList, this.voucherItem]));
    },
    // 删除一行
    handleTableDelItem(row, index) {
      if (this.voucherTmpList.length === 2) return this.toast('凭证模版不能少于2行！', 'warning');
      this.voucherTmpList = JSON.parse(JSON.stringify(this.voucherTmpList.filter((v, i) => i != index)));
    },
    getList() {
      this.loading = true;
      voucherTmpl(this.queryParams).then(({ success, data }) => {
        const { records, total } = data;
        if (success) {
          this.records = records;
          this.total = total;
        }
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.queryParams.page = 1;
      this.queryParams.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogType = 'add';
      this.visiable = true;
      this.bizType = '';
      this.tmplName = '';
      this.voucherTmpList = this.tmpVoucherTmpList;
    },
    // 确定
    handleDialog() {
      if (this._isVerify()) {
        const tmpArr = this.voucherTmpList.map((item) => {
          return {
            accountdirection: item.accountdirection,
            accountid: item.accountid,
            accountvaluefrom: item.accountvaluefrom,
            accountname: item.accountName,
            id: item.id
          };
        });

        const tmpParams = {
          bizType: this.bizType,
          tmplName: this.tmplName,
          items: tmpArr
        };
        // 新增模板
        this.dialogType == 'add'
          ? voucherAdd(tmpParams).then((res) => {
              this.visiable = false;
              res.success && this.toast('操作成功！', 'success');
              this.getList();
            })
          : voucherTmplItemUpdate({ ...tmpParams, id: this.curTmplId }).then((res) => {
              this.visiable = false;
              res.success && this.toast('操作成功！', 'success');
              this.getList();
            });
      }
    },
    _isVerify() {
      if (!this.bizType) return this.toast('业务类型必填！', 'warning');
      if (!this.tmplName) return this.toast('模板名称必填！', 'warning');
      const isExitEmptyVal = this.voucherTmpList.some((item) => {
        return !item.accountid || !item.accountdirection || !item.accountvaluefrom;
      });
      if (isExitEmptyVal) return this.toast('表格数据项存在空值!', 'warning');
      if (!isExitEmptyVal) {
        const tmpAcc = [...new Set(this.voucherTmpList.map((item) => item.accountdirection))];
        if (tmpAcc.length == 1) {
          this.toast('模板方向必须同时包含借贷!', 'warning');
          return false;
        }
        return true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.voucher_tmp_view {
  // height: calc(100vh - 160px);
  // min-height: 200px;
  // overflow-y: auto;
  // background: #fff;
  // box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
  // padding: 24px;
  // box-sizing: border-box;

  .search_box {
    padding: 20px 0 5px 20px;
  }

  .tmp_item {
    background: #fff;
    border: 1px solid #e9e9e9;
    font-size: 14px;
    .tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ebf4ff;
      padding: 0 20px;
      .btn_box {
        .el-button {
          padding: 0;
        }
      }
    }
    .con_box {
      height: 113px;
      overflow-y: auto;
    }
    .con {
      color: #666;
      margin: 0;
      padding: 10px 0 10px 20px;
      font-size: 12px;
      &.pl {
        padding-left: 46px;
      }
    }
    .con + .con {
      border-top: 1px solid #e9e9e9;
    }
  }
  .tmp_dialog {
    .con {
      padding: 0 20px 10px;

      .ipt_box {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 14px;
        margin-bottom: 16px;
        label {
          margin-right: 20px;
        }
      }
    }
  }
}
.add_template {
  margin: 0 0 20px 14px;
  height: 185px;
  padding-top: 300px;
  padding-bottom: 300px;
  font-size: 15px;
  // width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 82px;
  }
}
.voucher_tmp_view {
  .el-form--inline .el-form-item {
    margin: 0 20px 0 0;
  }
}
.tmp_dialog {
  .cus_tmp_table {
    border: 1px solid #9d5224;
  }
  .el-table--border .el-table__cell {
    border-right: 1px solid #9d5224;
    padding: 0;
  }
  .el-table th.el-table__cell.is-leaf {
    color: #9d5224;
    text-align: center;
    font-size: 14px;
  }
  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    border-bottom: 1px solid #9d5224;
    height: 40px;
    line-height: 40px;
  }
  .el-table--border th.el-table__cell:nth-child(4) {
    border-right: none;
  }
  .cus_tmp_select {
    .el-input {
      width: 258px;
    }
  }
}
</style>
